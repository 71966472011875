import React, { useState } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Inject, Toolbar } from '@syncfusion/ej2-react-grids';
import { replenishData, replenishGrid, replenishcompGrid, replenishcompData } from '../data/dummy';
import { Header } from '../components';


const Replenishment = () => {
  const [currentGrid, setCurrentGrid] = useState('replenish'); // Set the initial grid as 'replenish'
  const selectionsettings = { persistSelection: true };
  const toolbarOptions = ['ExcelExport']; // Remove 'Delete' and add 'ExcelExport' to export to Excel
  const editing = { allowDeleting: true, allowEditing: true };
  const customAttributes = { class: 'customcss' };
  const handleGridChange = (gridName) => {
    setCurrentGrid(gridName);
  };

  return (
    <div className="m-2 md:m-5 mt-24 p-2 md:p-5 bg-white rounded-3xl">
      <Header category="MONTHLY" title="REPLENISHMENT (2 WH)" />
      {/*<div className="grid-tab-buttons">
        
       <button className={currentGrid === 'replenish' ? 'active' : ''} onClick={() => handleGridChange('replenish')}>Replenish Grid</button>
        <button className={currentGrid === 'complete' ? 'active' : ''} onClick={() => handleGridChange('complete')}>Complete Grid</button>
        
      </div> */}
      <div className="grid-container">
        <GridComponent
          id="replenishmentGrid" // Add an ID to the GridComponent to reference it for exporting
          dataSource={currentGrid === 'replenish' ? replenishData : replenishcompData}
          enableHover={false}
          selectionSettings={selectionsettings}
          toolbar={toolbarOptions}
          editSettings={editing}
          allowTextWrap={false}
          allowResizing={false} // Enable column resizing
          allowScrolling={true}
          width="100%"
        >
          <ColumnsDirective>
            {currentGrid === 'replenish' ? (
              replenishGrid.map((item, index) => (
                <ColumnDirective
                  key={index}
                  {...item}
                  customAttributes={customAttributes}
                  
                />
              ))
            ) : (
              replenishcompGrid.map((item, index) => (
                <ColumnDirective
                  key={index}
                  {...item}
                  customAttributes={customAttributes}
                  
                />
              ))
            )}
          </ColumnsDirective>
          <Inject services={[Toolbar, ExcelExport, Resize]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default Replenishment;
